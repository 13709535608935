import { gql } from '@apollo/client';
import { FinancialAccounts } from 'financialAccounts/models/financialAccounts';

export interface FinancialAccountUpdateMutationInput {
  id: number;
  financialAccountLegalName: string;
  financialAccountDocumentId: string;
  financialAccountAddressPostalCode: number;
  financialAccountAddressState: string;
  financialAccountAddressCity: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressStreet: string;
  financialAccountAddressComplement: string;
}

export interface FinancialAccountUpdateMutationVariables {
  financialAccountUpdateInput: FinancialAccountUpdateMutationInput;
}

export interface FinancialAccountUpdated {
  financialAccount: FinancialAccounts;
}

export const FINANCIAL_ACCOUNT_UPDATE_MUTATION = gql`
  mutation financialAccountUpdate(
    $financialAccountUpdateInput: FinancialAccountUpdateInput!
  ) {
    financialAccountUpdate(input: $financialAccountUpdateInput) {
      id
      financialAccountAddressCity
      financialAccountAddressPostalCode
      financialAccountAddressState
      financialAccountAddressStreet
      financialAccountAddressComplement
      financialAccountDocumentId
      financialAccountLegalName
      financialAccountAddressDistrict
    }
  }
`;
