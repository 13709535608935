import React from 'react';
import classNames from 'classnames';

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    ref?: React.Ref<HTMLInputElement>;
    tooltipReference?: React.Ref<HTMLInputElement>;
    error?: string;
  },
  HTMLInputElement
>;

export const BaseInput = React.forwardRef<HTMLInputElement, Props>(
  ({ error, className, tooltipReference, ...props }, ref) => {
    return (
      <div ref={tooltipReference}>
        <input
          {...props}
          ref={ref}
          className={classNames(
            'appearance-none',
            { 'border-red-600 text-red-600 ring-red-600 mb-3': error },
            className ||
              'px-4 py-3 border border-gray-400 rounded shadow-sm placeholder-gray-dark-b6 focus:outline-none focus:ring-gray-background focus:border-gray-background'
          )}
        />
        {error && <p className="text-red-500 text-xs">{error}</p>}
      </div>
    );
  }
);

export default BaseInput;
