import classNames from 'classnames';
import SelectInput, { Props as BaseProps } from 'ui/components/form/SelectInput';

export type Props<T> = BaseProps<T> & {
  label: string;
  wrapperClassName?: string;
};

const SelectField = <T extends unknown>({
  className,
  wrapperClassName,
  label,
  disabled,
  id,
  ...props
}: Props<T>) => {
  const labelId = `${id}__label`;

  return (
    <fieldset className={wrapperClassName}>
      <label
        id={labelId}
        className={classNames('block text-sm font-medium text-gray-dark400 mb-3', {
          'text-gray-dark600': disabled,
        })}
      >
        {label}
      </label>
      <SelectInput {...props} disabled={disabled} aria-labelledby={labelId} />
    </fieldset>
  );
};

export default SelectField;
