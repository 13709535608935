import { gql } from '@apollo/client';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';

interface PowerDistributionUnitDataConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: PowerDistributionUnit[];
}

export interface PowerDistributionUnitList {
  powerDistributionUnitBillDatas: PowerDistributionUnitDataConnection;
}

export const powerDistributionUnitTypename =
  'PowerDistributionUnitBillDataConnection';

export const POWER_DISTRIBUTION_UNIT_BILL_DATAS_QUERY = gql`
  query powerDistributionUnitBillDatas(
    $before: String
    $after: String
    $pageSize: Int
  ) {
    powerDistributionUnitBillDatas(
      before: $before
      after: $after
      pageSize: $pageSize
    ) {
      entries {
        id
        billIssueDate
        billReferenceDate
        billConsumerUnitConsumptionGroupType
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
        consumerUnit {
          consumerUnitPowerDistributionUnitIdentifier
          cooperativeMember {
            cooperativeMemberLegalName
          }
          cooperative {
            cooperativeLegalName
          }
        }
        billConsumedEnergyCredits {
          consumedEnergyCreditsOffPeak
          consumedEnergyCreditsOnPeak
          consumedEnergyCreditsTotal
        }
        billConsumedEnergyKwh {
          consumedEnergyKwhOffPeak
          consumedEnergyKwhOnPeak
          consumedEnergyKwhTotal
        }
      }
      afterCursor
      beforeCursor
    }
  }
`;
