import { gql } from '@apollo/client';
import { FinancialAccounts } from 'financialAccounts/models/financialAccounts';

interface FinancialAccountConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: FinancialAccounts[];
}

export interface FinancialAccountList {
  financialAccounts: FinancialAccountConnection;
}

export const financialAccountsTypename = 'FinancialAccountConnection';

const MAX_PAGE_SIZE = 100;

export const FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const FINANCIAL_ACCOUNTS_QUERY = gql`
  query financialAccounts($after: String, $before: String, $pageSize: Int) {
    financialAccounts(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        financialAccountLegalName
        financialAccountEntityType
        financialAccountDocumentId
        financialAccountAddressState
        financialAccountAddressPostalCode
      }
    }
  }
`;
