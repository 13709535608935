import React from 'react';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    title: string;
    children: React.ReactNode;
  },
  HTMLInputElement
>;

export default function Section({ title, children }: Props) {
  return (
    <div className="w-full space-y-2 mb-6">
      <div className="border-b border-gray-dark600 w-full">
        <span className="text-md text-white font-medium">{title}</span>
      </div>
      {children}
    </div>
  );
}
