import { SyntheticEvent } from 'react';
import { GenerationUnit } from 'generationUnits/models/generationUnit';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { Tooltip } from 'ui/components/tooltip';

type Props = {
  generationUnit: GenerationUnit;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function GenerationUnitsTableItem({
  generationUnit: {
    id,
    generationUnitLegalName,
    generationUnitCapacityFactorAverage,
    generationUnitEnergySource,
    generationUnitAddressState,
    generationUnitPowerCapacity,
    powerDistributionUnit: { powerDistributionUnitLegalName },
    cooperative: { cooperativeLegalName },
    financialAccount: { financialAccountLegalName },
  },
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const GenerationUnitEnergySource = {
    UTE: 'Termelétrica',
    EOL: 'Eólica',
    UFV: 'Solar',
    CGH: 'Hidráulica',
  };

  return (
    <TableBodyRow dataTestId={`generation-unit-row-id-${id}`}>
      <TableBodyColumn>
        <Tooltip label={generationUnitLegalName} placement="bottom-start">
          <p>Fonte: {GenerationUnitEnergySource[generationUnitEnergySource]}</p>
          <p>Potência: {generationUnitPowerCapacity}kw</p>
          <p>FC: {generationUnitCapacityFactorAverage}</p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {generationUnitAddressState}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {financialAccountLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
