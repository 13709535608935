import { gql } from '@apollo/client';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

export type GenerationUnitMonthlyCapacityFactor = {
  generationUnitJanCapacityFactor: string;
  generationUnitFebCapacityFactor: string;
  generationUnitMarCapacityFactor: string;
  generationUnitAprCapacityFactor: string;
  generationUnitMayCapacityFactor: string;
  generationUnitJunCapacityFactor: string;
  generationUnitJulCapacityFactor: string;
  generationUnitAugCapacityFactor: string;
  generationUnitSepCapacityFactor: string;
  generationUnitOctCapacityFactor: string;
  generationUnitNovCapacityFactor: string;
  generationUnitDecCapacityFactor: string;
};

export interface GenerationUnitCreateMutationInput {
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: string;
  generationUnitLegalName: string;
  generationUnitCapacityFactorAverage: string;
  generationUnitEnergySource: string;
  generationUnitAddressState: string;
  generationUnitAddressCity: string;
  generationUnitAddressPostalCode: number;
  generationUnitAddressStreet: string;
  generationUnitAddressDistrict: string;
  generationUnitAddressComplement?: string;
  generationUnitConsumptionGroupType: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  generationUnitModality: string;
  generationUnitConsumptionClass: string;
  generationUnitPowerCapacity: number;
  generationUnitMonthlyCapacityFactor: GenerationUnitMonthlyCapacityFactor;
  generationUnitSdpId: string;
  cooperativeId: number;
  financialAccountId: number;
  powerDistributionUnitId: number;
}

export interface GenerationUnitMutationVariables {
  generationUnitCreateInput: GenerationUnitCreateMutationInput;
}

export interface GenerationUnitCreated {
  generationUnitCreate: GenerationUnit;
}

export const GENERATION_UNIT_CREATE_MUTATION = gql`
  mutation generationUnitCreate(
    $generationUnitCreateInput: generationUnitCreateInput!
  ) {
    generationUnitCreate(input: $generationUnitCreateInput) {
      id
      generationUnitConsumerUnitPowerDistributionUnitIdentifier
      generationUnitLegalName
      generationUnitCapacityFactorAverage
      generationUnitEnergySource
      generationUnitAddressCity
      generationUnitAddressPostalCode
      generationUnitAddressState
      generationUnitAddressStreet
      generationUnitAddressDistrict
      generationUnitAddressComplement
      powerDistributionUnitCredentialsUser
      generationUnitModality
      generationUnitConsumptionClass
      generationUnitConsumptionGroupType
      generationUnitPowerCapacity
      generationUnitMonthlyCapacityFactor {
        generationUnitJanCapacityFactor
        generationUnitFebCapacityFactor
        generationUnitMarCapacityFactor
        generationUnitAprCapacityFactor
        generationUnitMayCapacityFactor
        generationUnitJunCapacityFactor
        generationUnitJulCapacityFactor
        generationUnitAugCapacityFactor
        generationUnitSepCapacityFactor
        generationUnitOctCapacityFactor
        generationUnitNovCapacityFactor
        generationUnitDecCapacityFactor
      }
      generationUnitSdpId
      cooperative {
        id
        cooperativeLegalName
      }
      financialAccount {
        id
        financialAccountLegalName
      }
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
