import { SyntheticEvent } from 'react';
import { CooperativeMember } from 'cooperatives/models/cooperativeMember';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';

type Props = {
  cooperativeMember: CooperativeMember;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function PowerDistributionUnitsTableItem({
  cooperativeMember: {
    id,
    cooperativeMemberLegalName,
    cooperativeMemberEntityType,
    cooperativeMemberDocumentId,
    cooperative: { cooperativeLegalName },
  },
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`cooperative-member-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {cooperativeMemberLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {cooperativeMemberEntityType}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeMemberDocumentId}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
