import { gql } from '@apollo/client';
import {
  CooperativeMember,
  CooperativeMemberEntityType,
} from 'cooperatives/models/cooperativeMember';

export interface CooperativeMemberCreateMutationInput {
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeId: number;
}

export interface CooperativeMemberCreateMutationVariables {
  cooperativeMemberCreateInput: CooperativeMemberCreateMutationInput;
}

export interface CooperativeMemberCreated {
  cooperativeMemberCreate: CooperativeMember;
}

export const COOPERATIVE_MEMBER_CREATE_MUTATION = gql`
  mutation cooperativeMemberCreate(
    $cooperativeMemberCreateInput: cooperativeMemberCreateInput!
  ) {
    cooperativeMemberCreate(input: $cooperativeMemberCreateInput) {
      id
      cooperativeMemberEntityType
      cooperativeMemberDocumentId
      cooperativeMemberLegalName
      cooperative {
        cooperativeLegalName
      }
    }
  }
`;
