import TextField from 'ui/form/TextField';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import RadioField from 'ui/form/RadioField';
import { omit } from 'lodash';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';

export type FormFields = {
  financialAccountLegalName: string;
  financialAccountEntityType: string;
  financialAccountDocumentId: string;
  financialAccountAddressPostalCode: number;
  financialAccountAddressCity: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressStreet: string;
  financialAccountAddressComplement: string;
  financialAccountAddressState: string;
};

type Field =
  | 'financialAccountLegalName'
  | 'financialAccountEntityType'
  | 'financialAccountDocumentId'
  | 'financialAccountAddressPostalCode'
  | 'financialAccountAddressCity'
  | 'financialAccountAddressDistrict'
  | 'financialAccountAddressStreet'
  | 'financialAccountAddressComplement'
  | 'financialAccountAddressState';

export const FORM_FIELDS: Field[] = [
  'financialAccountLegalName',
  'financialAccountEntityType',
  'financialAccountDocumentId',
  'financialAccountAddressPostalCode',
  'financialAccountAddressCity',
  'financialAccountAddressDistrict',
  'financialAccountAddressStreet',
  'financialAccountAddressComplement',
  'financialAccountAddressState',
];

export type FormErrors = {
  financialAccountLegalName?: FieldError | undefined;
  financialAccountEntityType?: FieldError | undefined;
  financialAccountDocumentId?: FieldError | undefined;
  financialAccountAddressPostalCode?: FieldError | undefined;
  financialAccountAddressCity?: FieldError | undefined;
  financialAccountAddressDistrict?: FieldError | undefined;
  financialAccountAddressStreet?: FieldError | undefined;
  financialAccountAddressComplement?: FieldError | undefined;
  financialAccountAddressState?: FieldError | undefined;
};

export default function UserFormFields({
  errors,
  control,
  register,
  financialAccountEntityTypeDisabled,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  financialAccountEntityTypeDisabled?: boolean;
}) {
  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1">
          <Controller
            name="financialAccountEntityType"
            control={control}
            rules={{ required: !financialAccountEntityTypeDisabled }}
            render={({ field }) => (
              <RadioField
                {...omit(field, 'ref')}
                error={errors.financialAccountEntityType?.message}
                label="Entidade"
                id="financialAccountEntityType"
                disabled={financialAccountEntityTypeDisabled}
                options={[
                  {
                    label: 'Jurídica',
                    value: 'COMPANY',
                  },
                  {
                    label: 'Física',
                    value: 'INDIVIDUAL',
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="col-start-1">
          <TextField
            error={errors.financialAccountLegalName?.message}
            label="Nome"
            id="financialAccountLegalName"
            {...register('financialAccountLegalName', { required: true })}
            placeholder="Ex. Dois Vizinhos"
          />
        </div>
        <div className="col-start-2">
          <TextField
            error={errors.financialAccountDocumentId?.message}
            label="Documento"
            id="financialAccountDocumentId"
            {...register('financialAccountDocumentId', { required: true })}
            placeholder="Ex. 28.054.303/0001-06"
          />
        </div>

        <div className="col-start-1">
          <p className="text-gray-dark400 font-bold text-sm pt-4 pb-4">
            Endereço da conta financeira
          </p>
        </div>
        <div className="col-start-1">
          <NumberOnlyField
            error={errors.financialAccountAddressPostalCode?.message}
            label="CEP"
            id="financialAccountAddressPostalCode"
            {...register('financialAccountAddressPostalCode', {
              required: true,
              valueAsNumber: true,
            })}
            placeholder="81000000"
          />
        </div>
        <div className="col-start-2">
          <Controller
            name="financialAccountAddressState"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CountryStateSelectField
                {...omit(field, 'ref')}
                error={errors.financialAccountAddressState?.message}
                label="Estado"
                id="financialAccountAddressState"
              />
            )}
          />
        </div>
        <div className="col-start-3">
          <TextField
            error={errors.financialAccountAddressCity?.message}
            label="Cidade"
            id="financialAccountAddressCity"
            {...register('financialAccountAddressCity', { required: true })}
            placeholder="Ex.: Curitiba"
          />
        </div>
        <div className="col-start-1">
          <TextField
            error={errors.financialAccountAddressDistrict?.message}
            label="Bairro"
            id="financialAccountAddressDistrict"
            {...register('financialAccountAddressDistrict', { required: false })}
            placeholder="Ex.: Centro"
          />
        </div>
        <div className="col-start-2">
          <TextField
            error={errors.financialAccountAddressStreet?.message}
            label="Endereço"
            id="financialAccountAddressStreet"
            {...register('financialAccountAddressStreet', { required: true })}
            placeholder="Ex.: Comendador Franco 777"
          />
        </div>
        <div className="col-start-3">
          <TextField
            error={errors.financialAccountAddressComplement?.message}
            label="Complemento"
            id="financialAccountAddressComplement"
            {...register('financialAccountAddressComplement', {
              required: false,
            })}
            placeholder="Ex.: Sala 1"
          />
        </div>
      </div>
    </>
  );
}
