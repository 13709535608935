import { gql } from '@apollo/client';

export interface FinancialAccountQueryVariables {
  id: string;
}

export const financialAccountTypename = 'FinancialAccount';

export const FINANCIAL_ACCOUNT_QUERY = gql`
  query financialAccount($id: ID!) {
    financialAccount(id: $id) {
      id
      financialAccountAddressCity
      financialAccountAddressPostalCode
      financialAccountAddressState
      financialAccountAddressStreet
      financialAccountAddressComplement
      financialAccountDocumentId
      financialAccountLegalName
      financialAccountAddressDistrict
      financialAccountEntityType
    }
  }
`;
