import React from 'react';
import range from 'lodash/range';
import SelectField, { Props as SelectFieldProps } from 'ui/form/SelectField';
import { Section } from '../components';

const StatefulSelectField = (
  selectFieldProps: Partial<SelectFieldProps<string>>
) => {
  const [value, setValue] = React.useState<string | null>(null);

  return (
    <SelectField
      label="Options"
      options={[
        {
          key: 'key-1',
          value: 'Option 1',
        },
        {
          key: 'key-2',
          value: 'Option 2',
        },
        {
          key: 'key-3',
          value: 'Option 3',
        },
      ]}
      {...selectFieldProps}
      value={value}
      onChange={setValue}
      emptyOptionLabel="Select an option"
    />
  );
};

export const UiDashboard = () => {
  return (
    <div className="flex flex-col gap-y-6 w-full h-full bg-gray-dark800 p-4 overflow-y-auto">
      <Section title="Optional">
        <StatefulSelectField />
      </Section>
      <Section title="Required">
        <StatefulSelectField required />
      </Section>
      <Section title="More than 7 options">
        <StatefulSelectField
          options={range(1, 11).map((num) => ({
            key: `key-${num}`,
            value: `Options ${num}`,
          }))}
        />
      </Section>
      <Section title="Without Options">
        <StatefulSelectField options={[]} />
      </Section>
      <Section title="Disabled">
        <StatefulSelectField disabled />
      </Section>
      <Section title="With Error">
        <StatefulSelectField error="An error has occurred" />
      </Section>
    </div>
  );
};

export default UiDashboard;
