import { gql } from '@apollo/client';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

export type GenerationUnitMonthlyCapacityFactor = {
  generationUnitJanCapacityFactor: string;
  generationUnitFebCapacityFactor: string;
  generationUnitMarCapacityFactor: string;
  generationUnitAprCapacityFactor: string;
  generationUnitMayCapacityFactor: string;
  generationUnitJunCapacityFactor: string;
  generationUnitJulCapacityFactor: string;
  generationUnitAugCapacityFactor: string;
  generationUnitSepCapacityFactor: string;
  generationUnitOctCapacityFactor: string;
  generationUnitNovCapacityFactor: string;
  generationUnitDecCapacityFactor: string;
};

export interface GenerationUnitUpdateMutationInput {
  id: number;
  generationUnitLegalName: string;
  generationUnitEnergySource: string;
  generationUnitAddressPostalCode: number;
  generationUnitAddressCity: string;
  generationUnitAddressStreet: string;
  generationUnitAddressDistrict: string;
  generationUnitAddressState: string;
  generationUnitAddressComplement: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  generationUnitPowerCapacity: number;
  generationUnitSdpId: string;
  generationUnitMonthlyCapacityFactor: GenerationUnitMonthlyCapacityFactor;
}

export interface GenerationUnitUpdateMutationVariables {
  generationUnitUpdateInput: GenerationUnitUpdateMutationInput;
}

export interface GenerationUnitUpdated {
  generationUnit: GenerationUnit;
}

export const GENERATION_UNIT_UPDATE_MUTATION = gql`
  mutation generationUnitUpdate(
    $generationUnitUpdateInput: generationUnitUpdateInput!
  ) {
    generationUnitUpdate(input: $generationUnitUpdateInput) {
      id
      generationUnitConsumerUnitPowerDistributionUnitIdentifier
      generationUnitLegalName
      generationUnitCapacityFactorAverage
      generationUnitEnergySource
      generationUnitAddressCity
      generationUnitAddressPostalCode
      generationUnitAddressState
      generationUnitAddressStreet
      generationUnitAddressDistrict
      generationUnitAddressComplement
      powerDistributionUnitCredentialsUser
      generationUnitModality
      generationUnitConsumptionClass
      generationUnitConsumptionGroupType
      generationUnitPowerCapacity
      generationUnitMonthlyCapacityFactor {
        generationUnitJanCapacityFactor
        generationUnitFebCapacityFactor
        generationUnitMarCapacityFactor
        generationUnitAprCapacityFactor
        generationUnitMayCapacityFactor
        generationUnitJunCapacityFactor
        generationUnitJulCapacityFactor
        generationUnitAugCapacityFactor
        generationUnitSepCapacityFactor
        generationUnitOctCapacityFactor
        generationUnitNovCapacityFactor
        generationUnitDecCapacityFactor
      }
      generationUnitSdpId
      cooperative {
        id
        cooperativeLegalName
      }
      financialAccount {
        id
        financialAccountLegalName
      }
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
