import { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateCacheById } from 'graphql/apollo/cache';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import PowerDistributionUnitTariffGroupTable from 'powerDistributionUnits/components/table/PowerDistributionUnitTariffGroupTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { POWER_DISTRIBUTION_UNIT_TARIFF_GROUPS_LIST_QUERY } from 'powerDistributionUnits/graphql/powerDistributionUnitTariffGroupsQuery';
import {
  POWER_DISTRIBUTION_UNIT_TARIFF_GROUP_DELETE_MUTATION,
  PowerDistributionUnitTariffGroupDeleteMutationVariables,
  powerDistributionUnitTariffGroupTypename,
} from 'powerDistributionUnits/graphql/powerDistributionUnitTariffGroupDeleteMutaiton';
import { PowerDistributionUnitTariffGroupProps } from 'powerDistributionUnits/models/powerDistributionUnitTariffGroup';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { useHistory } from 'react-router';

type State = {
  powerDistributionUnitTariffGroups: PowerDistributionUnitTariffGroupProps[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de grupo tarifário',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Grupo tarifário deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar grupo tarifário',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const DASHBOARD_POWER_DISTRIBUTION_UNIT_TARIFF_GROUPS_CREATE_ROUTE =
  '/dashboard/taxes-fees/tariff-groups/create';

export default function PowerDistributionUnitTariffGroupsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [
    currentPowerDistributionUnitTariffGroupId,
    setcurrentcurrentPowerDistributionUnitTariffGroupId,
  ] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [state, setState] = useState<State>({
    powerDistributionUnitTariffGroups: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [
    powerDistributionUnitTariffGroupDeleteMutation,
    { loading: powerDistributionUnitTariffGroupDeleteLoading },
  ] = useMutation<PowerDistributionUnitTariffGroupDeleteMutationVariables>(
    POWER_DISTRIBUTION_UNIT_TARIFF_GROUP_DELETE_MUTATION,
    {
      onError() {
        addToast(DETETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    }
  );

  const [powerDistributionUnitTariffGroupsList, { data, loading, error, refetch }] =
    useLazyQuery(
      POWER_DISTRIBUTION_UNIT_TARIFF_GROUPS_LIST_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  useEffect(() => {
    powerDistributionUnitTariffGroupsList();
  }, [powerDistributionUnitTariffGroupsList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || powerDistributionUnitTariffGroupDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setState({
        powerDistributionUnitTariffGroups:
          data.powerDistributionUnitTariffGroups.entries,
        hasNextPage: !!data.powerDistributionUnitTariffGroups.afterCursor,
        hasPreviousPage: !!data.powerDistributionUnitTariffGroups.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data.powerDistributionUnitTariffGroups.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.powerDistributionUnitTariffGroups.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) =>
    push(`/dashboard/taxes-fees/tariff-groups/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setcurrentcurrentPowerDistributionUnitTariffGroupId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    powerDistributionUnitTariffGroupDeleteMutation({
      variables: { id: currentPowerDistributionUnitTariffGroupId },
      update(cache) {
        updateCacheById(
          cache,
          currentPowerDistributionUnitTariffGroupId,
          powerDistributionUnitTariffGroupTypename
        );
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () =>
    push(DASHBOARD_POWER_DISTRIBUTION_UNIT_TARIFF_GROUPS_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="Grupo tarifário"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Grupos tarifários" />
      }
    >
      <div className="rounded-lg">
        <PowerDistributionUnitTariffGroupTable
          powerDistributionUnitTariffGroups={state.powerDistributionUnitTariffGroups}
          onClickEditButton={onClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
        />
        <Pagination
          onNextClick={handleClickNext}
          onPreviousClick={handleClickBefore}
          disableNext={!state.hasNextPage}
          disableBefore={!state.hasPreviousPage}
        />
        <ConfirmOverlay
          title="Remoção do grupo tarifário!"
          text="Tem certeza que deseja deletar grupo tarifário? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
