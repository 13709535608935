import { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateCacheById } from 'graphql/apollo/cache';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import FinancialAccountsTable from 'financialAccounts/components/table/FinancialAccountsTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { FINANCIAL_ACCOUNTS_QUERY } from 'financialAccounts/graphql/financialAccountsQuery';
import {
  FINANCIAL_ACCOUNT_DELETE_MUTATION,
  financialAccountTypename,
  FinancialAccountDeleteMutationVariables,
} from 'financialAccounts/graphql/financialAccountDeleteMutation';
import { FinancialAccounts } from 'financialAccounts/models/financialAccounts';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { useHistory } from 'react-router';

type State = {
  financialAccounts: FinancialAccounts[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Contas Financeiras',
};

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Conta Financeira',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  text: 'Conta Financeira atualizada com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DASHBOARD_FINANCIAL_ACCOUNT_CREATE_ROUTE =
  '/dashboard/billing-cycle/financial-accounts/create';

export default function ListFinancialAccountsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [currentFinancialAccountId, setCurrentFinancialAccountId] =
    useState<number>(0);
  const [search, setSearch] = useState('');
  const [state, setState] = useState<State>({
    financialAccounts: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [
    financialAccountDeleteMutation,
    { loading: financialAccountsDeleteLoading },
  ] = useMutation<FinancialAccountDeleteMutationVariables>(
    FINANCIAL_ACCOUNT_DELETE_MUTATION,
    {
      onError() {
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
      },
    }
  );

  const [financialAccountList, { data, loading, error, refetch }] = useLazyQuery(
    FINANCIAL_ACCOUNTS_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    financialAccountList();
  }, [financialAccountList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || financialAccountsDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setState({
        financialAccounts: data.financialAccounts.entries,
        hasNextPage: !!data.financialAccounts.afterCursor,
        hasPreviousPage: !!data.financialAccounts.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data.financialAccounts.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.financialAccounts.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) =>
    push(`/dashboard/billing-cycle/financial-accounts/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentFinancialAccountId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    financialAccountDeleteMutation({
      variables: { id: currentFinancialAccountId },
      update(cache) {
        updateCacheById(cache, currentFinancialAccountId, financialAccountTypename);
      },
    });
  };

  const onCancelDelete = () => {
    closeConfirm();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () => push(DASHBOARD_FINANCIAL_ACCOUNT_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="Contas Financeiras"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Contas Financeiras" />
      }
    >
      <div className="rounded-lg">
        <FinancialAccountsTable
          financialAccounts={state.financialAccounts}
          onClickEditButton={onClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
        />
        <Pagination
          onNextClick={handleClickNext}
          onPreviousClick={handleClickBefore}
          disableNext={!state.hasNextPage}
          disableBefore={!state.hasPreviousPage}
        />
        <ConfirmOverlay
          title="Desativação de Conta Financeira!"
          text="Tem certeza que deseja desativar essa Conta Financeira? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
