import { gql } from '@apollo/client';
import { CooperativeMember } from 'cooperatives/models/cooperativeMember';

interface CooperativeMemberConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: CooperativeMember[];
}

export interface CooperativeMembersList {
  cooperativeMembers: CooperativeMemberConnection;
}

export const cooperativeMembersTypename = 'CooperativeMemberConnection';

export const COOPERATIVE_MEMBERS_QUERY = gql`
  query cooperativeMembers($after: String, $before: String, $pageSize: Int) {
    cooperativeMembers(before: $before, after: $after, pageSize: $pageSize) {
      entries {
        id
        cooperativeMemberEntityType
        cooperativeMemberDocumentId
        cooperativeMemberLegalName
        cooperative {
          cooperativeLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
