import { gql } from '@apollo/client';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

interface ConsumerUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ConsumerUnit[];
}

export interface ConsumerUnitsList {
  consumerUnits: ConsumerUnitConnection;
}

export const consumerUnitsTypename = 'ConsumerUnitConnection';

export const CONSUMER_UNITS_QUERY = gql`
  query consumerUnits($after: String, $before: String, $pageSize: Int) {
    consumerUnits(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        consumerUnitPowerDistributionUnitIdentifier
        consumerUnitAddressState
        consumerUnitConsumptionGroupType
        cooperative {
          cooperativeLegalName
        }
        cooperativeMember {
          cooperativeMemberLegalName
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
