import { gql } from '@apollo/client';
import {
  CooperativeMember,
  CooperativeMemberEntityType,
} from 'cooperatives/models/cooperativeMember';

export interface CooperativeMemberUpdateMutationInput {
  id: number;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeId: number;
}

export interface CooperativeMemberUpdateMutationVariables {
  cooperativeMemberUpdateInput: CooperativeMemberUpdateMutationInput;
}

export interface CooperativeMemberUpdated {
  cooperativeMemberUpdate: CooperativeMember;
}

export const COOPERATIVE_MEMBER_UPDATE_MUTATION = gql`
  mutation cooperativeMemberUpdate(
    $cooperativeMemberUpdateInput: CooperativeMemberUpdateInput!
  ) {
    cooperativeMemberUpdate(input: $cooperativeMemberUpdateInput) {
      id
      cooperativeMemberEntityType
      cooperativeMemberDocumentId
      cooperativeMemberLegalName
      cooperative {
        id
        cooperativeLegalName
      }
    }
  }
`;
