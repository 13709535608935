import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import TextField from 'ui/form/TextField';
import RadioField from 'ui/form/RadioField';
import SelectField from 'ui/form/SelectField';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import { Option } from 'ui/components/form/SelectInput';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';

import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';

import {
  GenerationUnitConsumptionClassTypes,
  GenerationUnitConsumptionGroupTypes,
  GenerationUnitModalityType,
  GenerationUnitEnergySourceTypes,
} from 'generationUnits/models/generationUnit';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { FinancialAccountSelect } from 'financialAccounts/models/financialAccounts';
import { Tooltip } from 'ui/components/tooltip';
import { MdInfo } from 'react-icons/md';
import { shift, useFloating, offset } from '@floating-ui/react-dom';

type Field =
  | 'generationUnitConsumerUnitPowerDistributionUnitIdentifier'
  | 'generationUnitLegalName'
  | 'generationUnitCapacityFactorAverage'
  | 'generationUnitEnergySource'
  | 'generationUnitAddressCity'
  | 'generationUnitAddressPostalCode'
  | 'generationUnitAddressState'
  | 'generationUnitAddressStreet'
  | 'generationUnitAddressDistrict'
  | 'generationUnitAddressComplement'
  | 'powerDistributionUnitCredentialsUser'
  | 'generationUnitModality'
  | 'generationUnitConsumptionClass'
  | 'generationUnitConsumptionGroupType'
  | 'generationUnitPowerCapacity'
  | 'generationUnitMonthlyCapacityFactor'
  | 'cooperativeId'
  | 'financialAccountId'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'generationUnitConsumerUnitPowerDistributionUnitIdentifier',
  'generationUnitLegalName',
  'generationUnitCapacityFactorAverage',
  'generationUnitEnergySource',
  'generationUnitAddressCity',
  'generationUnitAddressPostalCode',
  'generationUnitAddressState',
  'generationUnitAddressStreet',
  'generationUnitAddressDistrict',
  'generationUnitAddressComplement',
  'powerDistributionUnitCredentialsUser',
  'generationUnitModality',
  'generationUnitConsumptionClass',
  'generationUnitConsumptionGroupType',
  'generationUnitPowerCapacity',
  'generationUnitMonthlyCapacityFactor',
  'cooperativeId',
  'financialAccountId',
  'powerDistributionUnitId',
];

export type GenerationUnitMonthlyCapacityFactor = {
  generationUnitJanCapacityFactor: string;
  generationUnitFebCapacityFactor: string;
  generationUnitMarCapacityFactor: string;
  generationUnitAprCapacityFactor: string;
  generationUnitMayCapacityFactor: string;
  generationUnitJunCapacityFactor: string;
  generationUnitJulCapacityFactor: string;
  generationUnitAugCapacityFactor: string;
  generationUnitSepCapacityFactor: string;
  generationUnitOctCapacityFactor: string;
  generationUnitNovCapacityFactor: string;
  generationUnitDecCapacityFactor: string;
};

export type FormFields = {
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: string;
  generationUnitLegalName: string;
  generationUnitCapacityFactorAverage: string;
  generationUnitEnergySource: GenerationUnitEnergySourceTypes;
  generationUnitAddressState: string;
  generationUnitAddressCity: string;
  generationUnitAddressPostalCode: number;
  generationUnitAddressStreet: string;
  generationUnitAddressDistrict: string;
  generationUnitAddressComplement: string;
  generationUnitConsumptionGroupType: GenerationUnitConsumptionGroupTypes;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  generationUnitModality: GenerationUnitModalityType;
  generationUnitConsumptionClass: GenerationUnitConsumptionClassTypes;
  generationUnitPowerCapacity: number;
  generationUnitMonthlyCapacityFactor: GenerationUnitMonthlyCapacityFactor;
  generationUnitSdpId: string;
  cooperativeId: number;
  financialAccountId: number;
  powerDistributionUnitId: number;
};

export type FormErrors = {
  generationUnitConsumerUnitPowerDistributionUnitIdentifier?: FieldError | undefined;
  generationUnitLegalName?: FieldError | undefined;
  generationUnitCapacityFactorAverage?: FieldError | undefined;
  generationUnitEnergySource?: FieldError | undefined;
  generationUnitAddressState?: FieldError | undefined;
  generationUnitAddressCity?: FieldError | undefined;
  generationUnitAddressPostalCode?: FieldError | undefined;
  generationUnitAddressStreet?: FieldError | undefined;
  generationUnitAddressDistrict?: FieldError | undefined;
  generationUnitAddressComplement?: FieldError | undefined;
  generationUnitConsumptionGroupType?: FieldError | undefined;
  powerDistributionUnitCredentialsUser?: FieldError | undefined;
  powerDistributionUnitCredentialsPasswordInput?: FieldError | undefined;
  generationUnitModality?: FieldError | undefined;
  generationUnitConsumptionClass?: FieldError | undefined;
  generationUnitPowerCapacity?: FieldError | undefined;
  generationUnitJanCapacityFactor?: FieldError | undefined;
  generationUnitFebCapacityFactor?: FieldError | undefined;
  generationUnitMarCapacityFactor?: FieldError | undefined;
  generationUnitAprCapacityFactor?: FieldError | undefined;
  generationUnitMayCapacityFactor?: FieldError | undefined;
  generationUnitJunCapacityFactor?: FieldError | undefined;
  generationUnitJulCapacityFactor?: FieldError | undefined;
  generationUnitAugCapacityFactor?: FieldError | undefined;
  generationUnitSepCapacityFactor?: FieldError | undefined;
  generationUnitOctCapacityFactor?: FieldError | undefined;
  generationUnitNovCapacityFactor?: FieldError | undefined;
  generationUnitDecCapacityFactor?: FieldError | undefined;
  generationUnitSdpId?: FieldError | undefined;
  cooperativeId?: FieldError | undefined;
  financialAccountId?: FieldError | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

const generationUnitConsumptionClassOptions = [
  {
    key: 'COMMERCIAL',
    value: 'COMMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENTIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

const generationUnitEnergySourceOptions = [
  {
    key: 'UTE',
    value: 'Termelétrica',
  },
  {
    key: 'EOL',
    value: 'Eólica',
  },
  {
    key: 'UFV',
    value: 'Solar',
  },
  {
    key: 'CGH',
    value: 'Hidráulica',
  },
];

const LinkToGetSdpidCode =
  'https://www.figma.com/file/CSDG9lk958VGRo6q7mH6uj/MMVP?node-id=2%3A3#161603011';

export default function GenerationUnitFormFields({
  errors,
  register,
  powerDistributionUnits,
  cooperatives,
  financialAccounts,
  control,
  disableFields,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  cooperatives?: CooperativeSelect[];
  financialAccounts?: FinancialAccountSelect[];
  disableFields?: boolean;
  control: Control<FormFields>;
}) {
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom-end',
    middleware: [shift(), offset({ crossAxis: -5, mainAxis: -28 })],
  });

  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions(
        (powerDistributionUnits instanceof Array &&
          powerDistributionUnits.map((powerDistributionUnit) => ({
            key: powerDistributionUnit.id,
            value: powerDistributionUnit.powerDistributionUnitLegalName,
          }))) ||
          []
      ),
    [powerDistributionUnits]
  );

  const [cooperativeOptions, setCooperativeOptions] = useState<Option<number>[]>([]);
  useEffect(
    () =>
      setCooperativeOptions(
        (cooperatives instanceof Array &&
          cooperatives.map((cooperative) => ({
            key: cooperative.id,
            value: cooperative.cooperativeLegalName,
          }))) ||
          []
      ),
    [cooperatives]
  );

  const [financialAccountOptions, setFinancialAccountOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setFinancialAccountOptions(
        (financialAccounts instanceof Array &&
          financialAccounts.map((financialAccount) => ({
            key: financialAccount.id,
            value: financialAccount.financialAccountLegalName,
          }))) ||
          []
      ),
    [financialAccounts]
  );

  const [generationUnitModalities] = useState<Option<string>[]>([
    { key: 'GREEN', value: 'GREEN' },
    { key: 'BLUE', value: 'BLUE' },
    { key: 'CONVENTIONAL', value: 'CONVENTIONAL' },
  ]);

  const [generationUnitModalityOptions, setGenerationUnitModalityOptions] = useState<
    Option<string>[]
  >([]);
  useEffect(
    () =>
      setGenerationUnitModalityOptions(
        (generationUnitModalities instanceof Array &&
          generationUnitModalities.map((generationUnitModality) => ({
            key: `${generationUnitModality.key}`,
            value: generationUnitModality.value,
          }))) ||
          []
      ),
    [generationUnitModalities]
  );

  return (
    <>
      <div className="grid grid-cols-3 gap-8 mb-6">
        <TextField
          error={errors.generationUnitLegalName?.message}
          label="Nome da Usina"
          id="generationUnitLegalName"
          {...register('generationUnitLegalName', { required: true })}
          placeholder="Ex.: Lajeado Grande"
        />

        <Controller
          name="financialAccountId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.financialAccountId?.message}
              label="Conta Financeira"
              emptyOptionLabel="Escolha uma Conta Financeira"
              id="financialAccountId"
              options={financialAccountOptions}
              disabled={disableFields}
            />
          )}
        />
      </div>

      <div className="grid grid-cols-3 gap-8">
        <TextField
          error={
            errors?.generationUnitConsumerUnitPowerDistributionUnitIdentifier
              ?.message
          }
          label="Número da UC"
          id={'generationUnitConsumerUnitPowerDistributionUnitIdentifier'}
          {...register('generationUnitConsumerUnitPowerDistributionUnitIdentifier', {
            required: true,
          })}
          placeholder="Ex: 43213422"
          disabled={disableFields}
        />

        <Controller
          name="cooperativeId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.cooperativeId?.message}
              label="Cooperativa"
              emptyOptionLabel="Escolha uma Cooperativa"
              id="cooperativeId"
              options={cooperativeOptions}
              disabled={disableFields}
            />
          )}
        />

        <Controller
          name="powerDistributionUnitId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitId?.message}
              label="Concessionária"
              emptyOptionLabel="Escolha uma Concessionária"
              id="powerDistributionUnitId"
              options={powerDistributionUnitOptions}
              disabled={disableFields}
            />
          )}
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Informações Técnicas
      </p>

      <div className="grid grid-cols-3 gap-8 mb-6">
        <Controller
          name="generationUnitConsumptionGroupType"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <RadioField
              {...omit(field, 'ref')}
              error={errors.generationUnitConsumptionGroupType?.message}
              label="Grupo"
              id="generationUnitConsumptionGroupType"
              options={[
                {
                  label: 'Grupo A',
                  value: 'A',
                },
                {
                  label: 'Grupo B',
                  value: 'B',
                },
              ]}
              disabled={disableFields}
            />
          )}
        />

        <NumberOnlyField
          error={errors.generationUnitPowerCapacity?.message}
          label="Potência da Usina (kW)"
          id="generationUnitPowerCapacity"
          {...register('generationUnitPowerCapacity', {
            required: true,
            valueAsNumber: true,
          })}
          placeholder="Ex: 5000"
        />
      </div>

      <div className="grid grid-cols-3 gap-8 mb-6">
        <Controller
          name="generationUnitConsumptionClass"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitConsumptionClass?.message}
              label="Classe de Consumo"
              emptyOptionLabel="Escolha uma classe de consumo"
              id="generationUnitConsumptionClass"
              options={generationUnitConsumptionClassOptions}
              disabled={disableFields}
            />
          )}
        />

        <Controller
          name="generationUnitModality"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitModality?.message}
              label="Modalidade"
              emptyOptionLabel="Escolha uma Modalidade"
              id="generationUnitModality"
              options={generationUnitModalityOptions}
              disabled={disableFields}
            />
          )}
        />
        <Controller
          name="generationUnitEnergySource"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitEnergySource?.message}
              label="Fonte de Energia"
              emptyOptionLabel="Escolha a fonte de energia"
              id="generationUnitEnergySource"
              options={generationUnitEnergySourceOptions}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <TextField
          error={errors.powerDistributionUnitCredentialsUser?.message}
          label="Usuário da Concessionária"
          id="powerDistributionUnitCredentialsUser"
          {...register('powerDistributionUnitCredentialsUser', { required: true })}
          placeholder="Ex.: exe@gmail.com ou 28.054.303/0001-06"
        />
        <TextField
          error={errors.powerDistributionUnitCredentialsPasswordInput?.message}
          label="Senha da Concessionária"
          id="powerDistributionUnitCredentialsPasswordInput"
          {...register('powerDistributionUnitCredentialsPasswordInput', {
            required: !disableFields,
          })}
          placeholder="*******"
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Fator de capacidade médio mensal
      </p>
      <div className="grid grid-cols-6 gap-8">
        <TextField
          error={errors?.generationUnitJanCapacityFactor?.message}
          label="Janeiro"
          id="generationUnitJanCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJanCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitFebCapacityFactor?.message}
          label="Fevereiro"
          id="generationUnitFebCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitFebCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitMarCapacityFactor?.message}
          label="Março"
          id="generationUnitMarCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitMarCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitAprCapacityFactor?.message}
          label="Abril"
          id="generationUnitAprCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitAprCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitMayCapacityFactor?.message}
          label="Maio"
          id="generationUnitMayCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitMayCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitJunCapacityFactor?.message}
          label="Junho"
          id="generationUnitJunCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJunCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitJulCapacityFactor?.message}
          label="Julho"
          id="generationUnitJulCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJulCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitAugCapacityFactor?.message}
          label="Agosto"
          id="generationUnitAugCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitAugCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitSepCapacityFactor?.message}
          label="Setembro"
          id="generationUnitSepCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitSepCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitOctCapacityFactor?.message}
          label="Outubro"
          id="generationUnitOctCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitOctCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitNovCapacityFactor?.message}
          label="Novembro"
          id="generationUnitNovCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitNovCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          error={errors?.generationUnitDecCapacityFactor?.message}
          label="Dezembro"
          id="generationUnitDecCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitDecCapacityFactor',
            { required: true }
          )}
          placeholder="Ex.: 0.201"
        />
      </div>

      <hr className="mt-16 mb-4" />
      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Endereço da Usina
      </p>
      <div className="grid grid-cols-3 gap-8">
        <NumberOnlyField
          error={errors.generationUnitAddressPostalCode?.message}
          label="CEP"
          id="generationUnitAddressPostalCode"
          {...register('generationUnitAddressPostalCode', {
            required: true,
            valueAsNumber: true,
          })}
          placeholder="81000000"
        />
        <Controller
          name="generationUnitAddressState"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CountryStateSelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitAddressState?.message}
              label="Estado"
              id="generationUnitAddressState"
            />
          )}
        />
        <TextField
          error={errors.generationUnitAddressCity?.message}
          label="Cidade"
          id="generationUnitAddressCity"
          {...register('generationUnitAddressCity', { required: true })}
          placeholder="Ex.: Curitiba"
        />
        <TextField
          error={errors.generationUnitAddressDistrict?.message}
          label="Bairro"
          id="generationUnitAddressDistrict"
          {...register('generationUnitAddressDistrict', { required: true })}
          placeholder="Ex.: Centro"
        />
        <TextField
          error={errors.generationUnitAddressStreet?.message}
          label="Endereço"
          id="generationUnitAddressStreet"
          {...register('generationUnitAddressStreet', { required: true })}
          placeholder="Ex.: Comendador Franco 777"
        />
        <TextField
          error={errors.generationUnitAddressComplement?.message}
          label="Complemento"
          id="generationUnitAddressComplement"
          {...register('generationUnitAddressComplement', {
            required: false,
          })}
          placeholder="Ex.: Sala 1"
        />
        <div>
          <TextField
            error={errors.generationUnitSdpId?.message}
            label="Chave sdp id da usina"
            id="generationUnitSdpId"
            {...register('generationUnitSdpId', { required: false })}
            placeholder="Ex.: 6543bb3f-48f3-4e30-8ac1-040be8bb08b5"
            tooltipReference={reference}
          />

          <span className="text-xs text-gray-dark400">
            Para acessar essa informação
            <a
              href={LinkToGetSdpidCode}
              target={'_blank'}
              rel="noreferrer"
              className="cursor-pointer hover:underline pl-1 font-bold"
            >
              Obter código.
            </a>
          </span>
          <div
            style={{
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
            }}
            ref={floating}
          >
            <Tooltip
              label={<MdInfo color="#ccc" />}
              className={'w-88 pb-6'}
              placement="right-start"
              offset={{ crossAxis: -10, mainAxis: 15 }}
              opened
            >
              <div className="grid grid-cols-12">
                <div className="col-start-1">
                  <MdInfo color="#ccc" size={20} />
                </div>
                <div className="col-start-2 col-end-12">
                  <p>
                    <strong>Informação obrigatória</strong>
                  </p>
                  <br />
                  <p>
                    O <b>spid</b> é um código identificador da usina, obrigatório
                    para conectar com a API da Way2. Se não for preenchido não terá
                    inicio o ciclo de faturamento da usina. Para mais informações
                    clique em "Obter código".
                  </p>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="h-28" />
    </>
  );
}
