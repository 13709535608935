import { Button } from 'ui';
import { Props as ButtonProps } from 'ui/components/button/Button';

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick?: () => void;
};

export const CsvButton = ({ onClick, children, ...props }: Props) => {
  return (
    <Button onClick={onClick} {...props}>
      {children} CSV
    </Button>
  );
};

export default CsvButton;
