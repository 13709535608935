import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Dashboard, DashboardMainHeaderTitle } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import PowerDistributionUnitBillDatasTable from 'powerDistributionUnitBillData/components/table/PowerDistributionUnitBillDatasTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { POWER_DISTRIBUTION_UNIT_BILL_DATAS_EXPORT_QUERY } from 'powerDistributionUnitBillData/graphql/powerDistributionUnitBillDatasExportQuery';
import { POWER_DISTRIBUTION_UNIT_BILL_DATAS_QUERY } from 'powerDistributionUnitBillData/graphql/powerDistributionUnitBillDatasQuery';
import { PowerDistributionUnitBillData } from 'powerDistributionUnitBillData/models/powerDistributionUnitBillData';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import CsvButton from 'powerDistributionUnitBillData/components/CsvButton';
import { useCSVDownloader } from 'react-papaparse';
import { MdCloudDownload } from 'react-icons/md';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

type State = {
  powerDistributionUnitBillDatas: PowerDistributionUnitBillData[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de creditação',
};

export default function PowerDistributionUnitBillDatasPage() {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const [stateList, setStateList] = useState<State>({
    powerDistributionUnitBillDatas: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [stateExport, setStateExport] = useState<State>({
    powerDistributionUnitBillDatas: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [
    powerDistributionUnitBillDataListExport,
    { data: dataExport, loading: loadingExport, error: errorExport },
  ] = useLazyQuery(POWER_DISTRIBUTION_UNIT_BILL_DATAS_EXPORT_QUERY, {
    variables: {
      pageSize: 5000,
      before: null,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    powerDistributionUnitBillDataList,
    { data: dataList, loading: loadingList, error: errorList, refetch: refetchList },
  ] = useLazyQuery(
    POWER_DISTRIBUTION_UNIT_BILL_DATAS_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    powerDistributionUnitBillDataListExport();
    powerDistributionUnitBillDataList();
  }, [powerDistributionUnitBillDataList, powerDistributionUnitBillDataListExport]);

  const isError = errorList || errorExport;

  useEffect(() => {
    if (isError) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, isError]);

  useEffect(() => {
    if (loadingList) {
      showLoading();
      return;
    }

    if (dataList) {
      setStateList({
        powerDistributionUnitBillDatas:
          dataList.powerDistributionUnitBillDatas.entries,
        hasNextPage: !!dataList.powerDistributionUnitBillDatas.afterCursor,
        hasPreviousPage: !!dataList.powerDistributionUnitBillDatas.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, dataList, loadingList, showLoading]);

  useEffect(() => {
    if (loadingExport) {
      showLoading();
      return;
    }

    if (dataExport) {
      setStateExport({
        powerDistributionUnitBillDatas:
          dataExport.powerDistributionUnitBillDatas.entries,
        hasNextPage: !!dataExport.powerDistributionUnitBillDatas.afterCursor,
        hasPreviousPage: !!dataExport.powerDistributionUnitBillDatas.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, dataExport, loadingExport, showLoading]);

  const handleClickNext = () => {
    refetchList &&
      refetchList({
        after: dataList.powerDistributionUnitBillDatas.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetchList &&
      refetchList({
        after: null,
        before: dataList.powerDistributionUnitBillDatas.beforeCursor,
      });
  };

  const powerDistributionUnitBillDatasPortuguese: any[] = [];

  stateExport.powerDistributionUnitBillDatas.map(
    (item: PowerDistributionUnitBillData) =>
      powerDistributionUnitBillDatasPortuguese.push({
        id: item.id,
        'Unidade consumidora':
          item.consumerUnit.consumerUnitPowerDistributionUnitIdentifier,
        Concessionária: item.powerDistributionUnit.powerDistributionUnitLegalName,
        Grupo: item.billConsumerUnitConsumptionGroupType,
        Tipo: item.billConsumerUnitType,
        'Data Vencimento': item.billDueDate,
        'Data de erro': item.billIssueDate,
        'Leitura Futura': item.billNextReadingDate,
        'Leitura Final': item.billReadingFinishedAt,
        'Leitura Inicial': item.billReadingStartedAt,
        'Data Referência': item.billReferenceDate,
        'Valor Fatura': item.billTotalCents,
        'Dias Totais da Leitura': item.billTotalReadingDays,
        'Créditos consumidos fora ponta':
          item.billConsumedEnergyCredits.consumedEnergyCreditsOffPeak,
        'Créditos consumidos na ponta':
          item.billConsumedEnergyCredits.consumedEnergyCreditsOnPeak,
        'Total de créditos consumidos':
          item.billConsumedEnergyCredits.consumedEnergyCreditsTotal,
        'Tarifa de energia consumida fora ponta':
          item.billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsOffPeak,
        'Tarifa de energia consumida na ponta':
          item.billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsOnPeak,
        'Total tarifa de energia consumida':
          item.billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsTotal,
        'Energia consumida Kwh fora ponta':
          item.billConsumedEnergyKwh.consumedEnergyKwhOffPeak,
        'Energia consumida Kwh na ponta':
          item.billConsumedEnergyKwh.consumedEnergyKwhOnPeak,
        'Total energia consumida Kwh':
          item.billConsumedEnergyKwh.consumedEnergyKwhTotal,
        'Energia Excedente fora ponta':
          item.billExcessEnergyCredits.excessEnergyCreditsOffPeak,
        'Energia Excedente na ponta':
          item.billExcessEnergyCredits.excessEnergyCreditsOnPeak,
        'Total energia excedente':
          item.billExcessEnergyCredits.excessEnergyCreditsTotal,
        'Créditos gerados fora ponta':
          item.billGeneratedEnergyCredits.generatedEnergyCreditsOffPeak,
        'Créditos gerados na ponta':
          item.billGeneratedEnergyCredits.generatedEnergyCreditsOnPeak,
        'Total créditos gerados':
          item.billGeneratedEnergyCredits.generatedEnergyCreditsTotal,
      })
  );

  return (
    <Dashboard
      dashboardHeader={<div className="h-10" />}
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Creditação de Unidade Consumidora">
          <CSVDownloader
            type={Type.Link}
            filename={'bill-data'}
            bom={true}
            config={{
              delimiter: ';',
            }}
            data={powerDistributionUnitBillDatasPortuguese}
          >
            <CsvButton variant={'secundaryGray'} size="sm">
              <MdCloudDownload size={23} />
            </CsvButton>
          </CSVDownloader>
        </DashboardMainHeaderTitle>
      }
    >
      <div className="rounded-lg">
        <PowerDistributionUnitBillDatasTable
          powerDistributionUnitBillDatas={stateList.powerDistributionUnitBillDatas}
        />
        <Pagination
          onNextClick={handleClickNext}
          onPreviousClick={handleClickBefore}
          disableNext={!stateList.hasNextPage}
          disableBefore={!stateList.hasPreviousPage}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
