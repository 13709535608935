import { gql } from '@apollo/client';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';

export interface PowerDistributionUnitPisCofinsTaxCreateMutationInput {
  powerDistributionUnitId: number;
  pisCofinsValidityStartAt: Date;
  pisCofinsValidityEndAt: Date;
  pisCofinsPisFee: string;
  pisCofinsCofinsFee: string;
}

export interface PowerDistributionUnitPisCofinsTaxCreateMutationVariables {
  powerDistributionUnitPisCofinsTaxCreateInput: PowerDistributionUnitPisCofinsTaxCreateMutationInput;
}

export interface PowerDistributionUnitPisCofinsTaxCreated {
  powerDistributionUnitPisCofinsTaxCreate: PisCofinsTax;
}

export const POWER_DISTRIBUTION_UNIT_PIS_COFINS_TAX_CREATE_MUTATION = gql`
  mutation powerDistributionUnitPisCofinsTaxCreate(
    $powerDistributionUnitPisCofinsTaxCreateInput: PowerDistributionUnitPisCofinsTaxCreateInput!
  ) {
    powerDistributionUnitPisCofinsTaxCreate(
      input: $powerDistributionUnitPisCofinsTaxCreateInput
    ) {
      id
      pisCofinsValidityStartAt
      pisCofinsValidityEndAt
      pisCofinsPisFee
      pisCofinsCofinsFee
      powerDistributionUnitId
      powerDistributionUnit {
        powerDistributionUnitLegalName
        powerDistributionUnitCountryState
      }
    }
  }
`;
