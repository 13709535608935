import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import type { SubMenuSidebar } from 'dashboard/models/navigation';

type Props = {
  to: string | undefined;
  icon: ReactNode;
  isCurrentPage: boolean;
  name: string;
  subtitle: string;
  submenu?: SubMenuSidebar[];
};

export default function NavigationItem({
  to,
  icon,
  isCurrentPage,
  name,
  subtitle,
  submenu,
}: Props) {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Menu>
      {({ open }) => (
        <>
          <a href={to}>
            <div ref={setTargetElement} className="rounded-md shadow-sm">
              {submenu ? (
                <Menu.Button
                  aria-current={isCurrentPage ? 'page' : undefined}
                  className={classNames(
                    'hover:bg-gray-dark800 group w-full p-3 rounded-md text-gray-dark500 hover:text-gray-dark400 flex flex-col items-center text-center text-xs font-medium',
                    {
                      'bg-gray-dark800': open,
                    }
                  )}
                >
                  {icon}
                  <span className="mt-2">{name}</span>
                </Menu.Button>
              ) : (
                <button
                  aria-current={isCurrentPage ? 'page' : undefined}
                  className={classNames(
                    'hover:bg-gray-dark800 group w-full p-3 rounded-md text-gray-dark500 hover:text-gray-dark400 flex flex-col items-center text-center text-xs font-medium',
                    {
                      'bg-gray-dark800': open,
                    }
                  )}
                >
                  {icon}
                  <span className="mt-2">{name}</span>
                </button>
              )}
            </div>
          </a>

          {submenu && (
            <Portal>
              <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                >
                  <Menu.Items
                    static
                    className="w-56 origin-top-right text-gray-dark500 bg-gray-dark800 border border-gray-dark800 rounded-md shadow-lg outline-none"
                  >
                    <div className="px-4 py-3">
                      <p className="text-xs font-semibold">{subtitle}</p>
                    </div>

                    <div className="py-1">
                      {submenu.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.to}
                              className={classNames(
                                'flex items-center w-full px-4 py-2 text-sm font-semibold leading-5 text-left hover:text-gray-dark400 hover:bg-gray-dark700',
                                active ? 'text-gray-dark-b6' : 'text-gray-dark-6b'
                              )}
                            >
                              <item.icon
                                className="h-4 w-4 mr-3"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                  <div
                    ref={setArrowElement}
                    style={styles.arrow}
                    className="-left-1"
                  >
                    <div className="w-2 h-2 bg-gray-dark800 rotate-45" />
                  </div>
                </Transition>
              </div>
            </Portal>
          )}
        </>
      )}
    </Menu>
  );
}
