import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { CooperativeMemberEntityType } from 'cooperatives/models/cooperativeMember';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import RadioField from 'ui/form/RadioField';
import omit from 'lodash/omit';

type Field =
  | 'cooperativeMemberLegalName'
  | 'cooperativeMemberDocumentId'
  | 'cooperativeMemberEntityType'
  | 'cooperativeId';

export const FORM_FIELDS: Field[] = [
  'cooperativeMemberLegalName',
  'cooperativeMemberDocumentId',
  'cooperativeMemberEntityType',
  'cooperativeId',
];

export type FormFields = {
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeId: number;
};

export type FormErrors = {
  cooperativeMemberLegalName?: FieldError | undefined;
  cooperativeMemberDocumentId?: FieldError | undefined;
  cooperativeMemberEntityType?: FieldError | undefined;
  cooperativeId?: FieldError | undefined;
};

export default function CooperativeFormFields({
  cooperatives,
  errors,
  control,
  register,
  disabledCooperative,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  cooperatives?: CooperativeSelect[];
  control: Control<FormFields>;
  disabledCooperative?: boolean;
}) {
  const cooperativeId = useWatch<FormFields, 'cooperativeId'>({
    control,
    name: 'cooperativeId',
  });
  const [cooperativeOptions, setcooperativeOptions] = useState<Option<number>[]>([]);
  useEffect(
    () =>
      setcooperativeOptions(
        cooperatives?.map((cooperative) => ({
          key: cooperative.id,
          value: cooperative.cooperativeLegalName,
        })) || []
      ),
    [cooperatives]
  );

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1 col-end-4">
          <Controller
            name="cooperativeMemberEntityType"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, value } }) => (
              <RadioField
                {...omit(ref, 'ref', 'value')}
                error={errors.cooperativeMemberEntityType?.message}
                label="Entidade"
                id="cooperativeMemberEntityType"
                options={[
                  {
                    label: 'Jurídica',
                    value: 'COMPANY',
                  },
                  {
                    label: 'Física',
                    value: 'INDIVIDUAL',
                  },
                ]}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-start-1 col-end-2">
          <TextField
            error={errors.cooperativeMemberLegalName?.message}
            label="Razão Social/Nome"
            id="cooperativeMemberLegalName"
            {...register('cooperativeMemberLegalName', { required: true })}
            placeholder="Ex.: Nex Energy Gestão de Energia"
          />
        </div>

        <div className="col-start-2 col-end-3">
          <TextField
            error={errors.cooperativeMemberDocumentId?.message}
            label="Documento"
            id="cooperativeMemberDocumentId"
            {...register('cooperativeMemberDocumentId', { required: true })}
            placeholder="Ex.: 28.054.303/0001-06"
          />
        </div>

        <div className="col-start-3 col-end-4">
          <Controller
            name="cooperativeId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectField
                {...omit(field, 'ref', 'value')}
                label="Cooperativa"
                emptyOptionLabel="Selecione uma Cooperativa"
                id="cooperativeId"
                options={cooperativeOptions}
                value={cooperativeId}
                disabled={disabledCooperative}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
