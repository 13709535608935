import { PowerDistributionUnitBillData } from 'powerDistributionUnitBillData/models/powerDistributionUnitBillData';
import PowerDistributionUnitBillDatasTableItem from './PowerDistributionUnitBillDatasTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  powerDistributionUnitBillDatas: PowerDistributionUnitBillData[];
};

export default function PowerDistributionUnitDatasTable({
  powerDistributionUnitBillDatas,
}: Props) {
  return (
    <>
      {/* Add the filter here */}
      <Table>
        <TableHead>
          <TableHeadColumn>Mês Fat.</TableHeadColumn>
          <TableHeadColumn>Cooperado</TableHeadColumn>
          <TableHeadColumn>UC</TableHeadColumn>
          <TableHeadColumn>Data Disp.</TableHeadColumn>
          <TableHeadColumn>Cooperativa</TableHeadColumn>
          <TableHeadColumn>Conc.</TableHeadColumn>
          <TableHeadColumn>Consu.</TableHeadColumn>
          <TableHeadColumn>Comp.</TableHeadColumn>
          <TableHeadColumn className="text-right">Fatura</TableHeadColumn>
        </TableHead>
        <TableBody>
          {powerDistributionUnitBillDatas.map(
            (powerDistributionUnitData: PowerDistributionUnitBillData) => (
              <PowerDistributionUnitBillDatasTableItem
                key={powerDistributionUnitData.id}
                powerDistributionUnitBillDatas={powerDistributionUnitData}
              />
            )
          )}
        </TableBody>
      </Table>
    </>
  );
}
